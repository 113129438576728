import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { Observable, catchError, switchMap, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  ssrCookieService = inject(SsrCookieService);
  constructor(private authService: AuthService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.authService.checkUserAppLanguage()) {
      this.authService.setUserAppLanguage();
    }

    if (req.url.includes('signin')) {
      req = req.clone({
        withCredentials: true,
      });
    } else {
      let headers = req.headers.set(
        'Accept-Language',
        this.authService.getUserAppLanguage()
      );
      // Add Current-Site to header if exist
      if (this.ssrCookieService.check('current-site')) {
        headers = req.headers
          .set('Accept-Language', this.authService.getUserAppLanguage())
          .set('Current-Site', this.authService.getUserCurrentSite());
      }

      req = req.clone({
        withCredentials: true,
        headers: headers,
      });
    }

    return next.handle(req).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          !req.url.includes('signin') &&
          error.status === 401
        ) {
          return this.handle401Error(req, next);
        }
        return throwError(() => error);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;

      if (this.authService.isSignedIn()) {
        return this.authService.refreshToken().pipe(
          switchMap(() => {
            this.isRefreshing = false;
            return next.handle(request);
          }),
          catchError((error) => {
            this.isRefreshing = false;

            if (error.status == '403') {
              this.authService.signOut();
            }
            return throwError(() => error);
          })
        );
      }
    }
    return next.handle(request);
  }
}
