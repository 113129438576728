import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrl: './auth-layout.component.scss',
})
export class AuthLayoutComponent {
  showAuthHeader: boolean = true;

  constructor(private router: Router) {
    router.events.subscribe((val) => {
      this.updateHeaderVisibility();
      // if (val instanceof NavigationEnd) {
      // }
    });
  }

  ngOnInit() {
    // console.log('NgOnInit');
    this.updateHeaderVisibility();
  }

  updateHeaderVisibility() {
    this.showAuthHeader =
      this.router.url != '/auth' && !this.router.url.includes('/register')
        ? false
        : true;
  }
}
