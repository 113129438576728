<section class="auth" fxLayout="row" fxLayoutAlign="center" fxLayoutGap="0">
  <!-- Auth Image -->
  <div class="auth-img" fxFlex="50"></div>
  <!-- / Auth Image -->
  <!-- Auth Content -->
  <div
    fxFlex="100"
    fxFlex.gt-md="50"
    fxLayout="column"
    fxLayoutAlign="center center"
    class="auth-content"
  >
    <!-- <div style="height: 100%"> -->
    <router-outlet></router-outlet>
    <!-- </div> -->

    <!-- class="btn-return" -->
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="5px"
      routerLink="/"
      *ngIf="!showAuthHeader"
      class="btn-return"
    >
      <mat-icon>keyboard_backspace</mat-icon>
      <span>Retour à la page de connexion</span>
    </div>
  </div>
  <!-- / Auth Content -->
</section>
